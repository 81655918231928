<template>
  <PrintWrapper>
    <div class="waiBox mt10" id="printTest1">
      <div class="handle-box" v-if="isSendMail && !isInvoice">
        <button type="button" class="btn-send" @click="sendMail">
          {{ lang === 'en' ? 'Send Email' : '发送邮件' }}
        </button>
      </div>
      <div id="pdfDom">
        <!-- 头部 -->
        <div class="headBox">
          <div class="imgBox">
            <img v-if="detail.exportsRise == 'RG'" :src="RG" alt />
            <img v-else-if="detail.exportsRise == 'RP'" :src="RP" alt />
            <img v-else-if="detail.exportsRise == 'SW'" :src="SW" alt />
          </div>
          <div class="txtBox" v-if="detail">
            <p class="pTL">{{ isInvoice ? 'INVOICE' : 'PROFORMA INVOICE' }}</p>
            <p class="txtBoxLine">
              <span>DATE</span>
              <i>{{ dateConversionFn(detail.date) }}</i>
            </p>
            <!-- <p class="txtBoxLine">
              <span>Invoice NO</span>
              <i>{{ detail.piNO }}</i>
            </p> -->
            <p class="txtBoxLine">
              <span>PI NO</span>
              <i>{{ detail.piNO }}</i>
            </p>
            <p class="txtBoxLine">
              <span>PO</span>
              <i>{{ detail.clientPO }}</i>
            </p>
          </div>
        </div>
        <!-- SHIP TO -->
        <div class="shipBillPanle">
          <div class="shipPanle">
            <h3>SHIP TO</h3>
            <div v-if="detail.addressVOS && detail.addressVOS.length">
              <div v-for="(item, i) in detail.addressVOS" :key="i" class="shipPanleCont">
                <p>{{ item.companyName }}</p>
                <p>{{ item.address }}</p>
                <p>{{ item.consigneeName }}</p>
                <p>{{ item.consigneePhone }}</p>
              </div>
            </div>
          </div>
          <div class="billPanle" v-if="detail">
            <h3>BILL TO</h3>
            <div class="billPanleCont">
              <p class="txtBoxLine">
                <span>Cust Name</span>
                <i>{{ detail.custName }}</i>
              </p>
              <p class="txtBoxLine">
                <span>Attn</span>
                <i>{{ detail.linkman }}</i>
              </p>
              <p class="txtBoxLine">
                <span>Tel</span>
                <i>{{ detail.customerPhone }}</i>
              </p>
              <p class="txtBoxLine">
                <span>Add</span>
                <i>{{ detail.add }}</i>
              </p>
            </div>
          </div>
        </div>
        <!-- 表格 -->
        <div class="tableBox">
          <table id="tableExcel" width="700px" border="1" cellspacing="0" cellpadding="5px">
            <thead>
              <tr>
                <th width="90px">Item</th>
                <th style="word-break: break-all">Description</th>
                <th width="110px" class="pl20">In Hands Date</th>
                <th width="80px" class="text-tr">
                  Unit Price
                </th>
                <th width="90px" class="text-tr">
                  Qty
                </th>
                <th width="110px" class="text-tr">Amount</th>
              </tr>
            </thead>

            <tbody v-for="(item, i) in tableData" :key="i">
              <tr>
                <td>
                  <p>{{ item.sku }}</p>
                </td>
                <td style="text-align: left; word-break: break-all">
                  <p>{{ item.productEn }}</p>
                  <p v-show="item.specification && item.specification != '/'" class="fc-gray">
                    Specification:{{ item.specification }};
                  </p>
                  <p class="text-warp fc-gray">{{ item.productDescription }}</p>
                  <p class="text-warp fc-gray">
                    {{ item.printingEffect }}
                    <!-- <ErpWrapText :text="item.printingEffect"   /> -->
                  </p>
                </td>
                <!-- <td>
                  <p v-if="item.productExtend && item.productExtend.shippingMethodEn">{{ item.productExtend.shippingMethodEn}}</p>
                </td> -->
                <td class="pl20">
                  <p v-if="item.productExtend && item.productExtend.customerDeliveryDate">{{ dateConversionFn(item.productExtend.customerDeliveryDate) }}</p>
                </td>
                <td class="text-tr">
                  <p v-if="isViewCost">
                    $&nbsp;{{ item.netPrice }}
                  </p>
                  <NoViewCost v-else />
                </td>
                <td class="text-tr">
                  <p v-if="isViewCost">{{ addThousandSeparatorFn(item.amount) }}</p>
                  <NoViewCost v-else />
                </td>
                <td class="text-tr">
                  <p>$&nbsp;{{ addThousandSeparatorFn(item.usTotle) }}</p>
                </td>
              </tr>
              <tr v-show="Number(item.setUpCharge) > 0">
                <td>Setup</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-tr">
                  <span v-if="isViewCost">
                    $&nbsp;{{ item.setUpCharge }}
                  </span>
                  <NoViewCost v-else />
                </td>
              </tr>
              <tr v-show="Number(item.shippingFee) > 0">
                <td>Shipping Fee</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-tr">
                  <span v-if="isViewCost">
                    $&nbsp;{{ item.shippingFee }}
                  </span>
                  <NoViewCost v-else />
                </td>
              </tr>
              <tr v-show="item.otherCharge">
                <td>Other Fee</td>
                <td class="text-warp" style="text-align: left">
                 {{ item.chargeFees }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-tr">
                  <span v-if="isViewCost">
                    $&nbsp;{{ item.otherCharge }}
                  </span>
                  <NoViewCost v-else />
                </td>
              </tr>
              <tr v-show="item.discountAmount">
                <td>Discount</td>
                <td class="text-warp" style="text-align: left">
                  {{ item.discountReason }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-tr">
                  <span v-if="isViewCost">
                    $&nbsp; {{ Number(item.discountAmount) > 0 ? '-' : ''
                    }}{{ item.discountAmount }}
                  </span>
                  <NoViewCost v-else />
                </td>
              </tr>
            </tbody>
            <!-- <tfoot>
              <tr>
                <td>TOTAL</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div v-if="isViewCost">
                    <span v-if="detail.totle">
                      {{ parseFloat(detail.totle).toFixed(2) }}
                    </span>
                  </div>
                  <NoViewCost v-else />
                </td>
              </tr>
            </tfoot> -->
          </table>
        </div>
        <!-- 总计 -->
        <div class="tbxia">
          <p>
           <span class="tbxia-title"> Total</span>
            <span class="tbxia-cont">
              <i v-if="isViewCost">
              {{ detail.currency == '人民币' ? '￥' : '$' }}
              {{ detail.totle ? addThousandSeparatorFn(parseFloat(detail.totle).toFixed(2)) : '0.00' }}
              </i>
              <NoViewCost v-else />
            </span>
          </p>
          <p>
            <span class="tbxia-title">Deposit</span>
            <span class="tbxia-cont">
              <i v-if="isViewCost">
                {{ detail.currency == '人民币' ? '￥' : '$' }}
                {{
                detail.deposit ? addThousandSeparatorFn(parseFloat(detail.deposit).toFixed(2)) : '0.00'
                }}
              </i>
              <NoViewCost v-else />
            </span>
          </p>
        </div>
        <!-- 左右列表-->
        <div class="lrlist">
          <div class="llist" v-show="!isInvoice">
            <p class="lblue mb8" v-if="detail.payNowLink">
              <a class="c_pointer" @click="payNow(detail.payNowLink)">
                If paid by the credit card, click this link.
               <em class="lblue emText"> PAY NOW</em>
              </a>
            </p>
            <p class="mb8" style="height: 16px;" v-else></p>
            <p>
              Warmly Reminder: <span>3% handling fee will be charged if paid by the
                credit card</span>; You may avoid this charge by using the check or the
              wire transfer.
            </p>
          </div>

          <div class="rlist">
            <h3 class="mb8">Address for sending CHECK</h3>
            <p v-if="detail.exportsRise == 'RG'">Rivers Garment Inc.</p>
            <p v-if="detail.exportsRise == 'RP'" >Rivers Promo Incorporation</p>
            <p v-if="detail.exportsRise == 'SW'">Slight Worlds Inc.</p>
            <p>13360 Molette St, Santa Fe Springs, CA 90670</p>
            <p v-if="detail.exportsRise == 'RG'">Tel: 1-562-968-0917&nbsp;&nbsp;&nbsp;&nbsp;Fax: 1-323-446-7173</p>
            <p v-if="detail.exportsRise == 'RP'" >Tel: 1-323-282-3208&nbsp;&nbsp;&nbsp;&nbsp;Fax: 1-323-446-7173</p>
            <p v-if="detail.exportsRise == 'SW'">Tel: (626)539-9965&nbsp;&nbsp;&nbsp;&nbsp;Fax: (626)552-3710</p>
            <p>ATTN: Account Receiver</p>
          </div>
        </div>
       <div class="info-panel">
         <!-- 信息 -->
          <div class="ftTxt" v-show="!isInvoice">
            <p class="txtBoxLine"><span>Port of loading</span><i>{{ detail.portOfLoading }}</i></p>
            <p class="txtBoxLine"><span>Port of destination</span><i>{{ detail.destination }}</i></p>
            <!-- <p class="txtBoxLine"><span>Ship terms</span><i>{{ detail.shipTerms }}</i></p>
            <p class="txtBoxLine"><span>IN HANDS DATE</span><i>{{ detail.deliveryDate }}</i></p> -->
            <p class="txtBoxLine"><span>Payment Terms</span><i>{{ detail.paymentTerms }}</i></p>
          </div>
          <!-- name -->
          <div class="ftName">
            <!-- <p class="txtBoxLine" v-show="!isInvoice"><span>Buyer</span><i></i></p> -->
            <p class="txtBoxLine"><span>Sales</span><i>{{ detail.sales }}</i></p>
          </div>
       </div>
        <!-- REMARK -->
        <div class="ftbb">
          <p>{{ detail.remark }}</p>
          <p></p>
        </div>
        <div v-if="detail.exportsRise == 'RG'" class="lastDiv">
          <p>
            By placing your order, you agree to the Rivers Garment's Terms and
            Conditions.
          </p>
          <a style="text-decoration: underline;font-style: italic;">
            https://www.riversgarment.com/terms-and-conditions
          </a>
        </div>
        <div v-else-if="detail.exportsRise == 'RP'" class="lastDiv">
          <p>
            By placing your order, you agree to the Rivers Promo's Terms and
            Conditions.
          </p>
          <a style="text-decoration: underline;font-style: italic;">
            https://www.riverspromo.com/terms-and-conditions
          </a>
        </div>
        <div v-else-if="detail.exportsRise == 'SW'" class="lastDiv">
          <p>
            By placing your order, you agree to the Slight Worlds's Terms and
            Conditions.
          </p>
          <a style="text-decoration: underline;font-style: italic;">
            http://www.slightworlds.com/terms-and-conditions
          </a>
        </div>
      </div>
    </div>
  </PrintWrapper>
</template>

<script>
  import { orderPrintOrder, orderPreviewOrder, orderpdf } from '@/api/order.js'
  import PrintWrapper from './printWrapper'
  import viewCost from '@/views/order/orderList/util'
  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import { keyStr } from '@/config'
  import { base64Decode,addThousandSeparator } from 'kits' 
  import Decimal from 'decimal.js'
  import { mapGetters } from 'vuex'
  export default {
    props: ['isView'],
    data() {
      return {
        isViewCost: viewCost(),
        orderId: '',
        detail: {},
        RG: require('@/assets/images/RG.png'),
        RP: require('@/assets/images/RP.png'),
        SW: require('@/assets/images/SW.png'),
        isSendMail: true,
        custEmail: '',
        isInvoice: false,
        printObj: {
          id: 'printTest1',
          popTitle: '', //this.param.title
        },
        tableData: [],
      }
    },
    components: {
      PrintWrapper,
      NoViewCost,
    },
    created() {
      this.orderId = this.$route.query.orderId
      this.isInvoice = this.$route.query.isInvoice || false
      if (!this.isView) this.getPrintInfo()
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    methods: {
      // 千分符
      addThousandSeparatorFn(val) {
        return addThousandSeparator(val)
      },
      // 转为美国日期格式
      dateConversionFn(dateValue) {
        if(!dateValue) return '';
        const date = new Date(dateValue);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(date);
        return formattedDate
      },
      // 获取打印信息
      getPrintInfo() {
        const params = {
          orderId: this.$route.query.orderId,
          printType: this.isInvoice ? 4 : 1,
          checkFlag: this.isViewCost ? 1 : 0,
        }
        if (params.orderId) {
          orderPrintOrder(params).then((res) => {
            if (res?.code === '000000') {
              // this.detail = this.isInvoice
              //   ? res?.data?.printInvoiceVO
              //   : res?.data?.printContractVOEncode &&
              //   JSON.parse(base64Decode(res.data.printContractVOEncode, keyStr))
              this.detail = this.isInvoice
                ? res?.data?.printInvoiceVO
                : res?.data?.printContractVO
              this.custEmail = this.isInvoice
                ? this.detail.custEmail
                : this.detail.custEmail
              // this.tableData = this.detail.orderProductVOS
              this.tableData = this.setShippingFeeTotal(this.detail?.orderProductVOS || [])
              this.handleShippingFee()
              console.log(  JSON.parse(base64Decode(res.data.printContractVOEncode, keyStr)))
            }

          })
        }
      },

      // 相同SPU（productCode）只展示一个总运费，为该SPU中所有项目的运费总和
      setShippingFeeTotal(list) {
        list = JSON.parse(JSON.stringify(list))
        const feeMap = new Map()
        // 遍历数组，计算每个 productCode 的总运费
        list.forEach((item, index) => {
          const { productCode, shippingFee } = item
          const fee = parseFloat(shippingFee) || 0
          if (!feeMap.has(productCode)) {
            feeMap.set(productCode, { totalFee: 0, lastIndex: index })
          }
          const productData = feeMap.get(productCode)
          productData.totalFee = Decimal(productData.totalFee).add(fee).toNumber()
          productData.lastIndex = index // 更新最后一个对象的索引
        })
        // 更新数组中的对象
        return list.map((item, index) => {
          const productData = feeMap.get(item.productCode)
          return {
            ...item,
            shippingFee: index !== productData.lastIndex ? 0 : Decimal(productData.totalFee).toFixed(2)
          }
        })
      },

      getPrintInfoView(data) {
        orderPreviewOrder(data).then((res) => {
          if (res?.code === '000000' && res?.data) {
            this.detail = res.data
            // this.tableData = res.data.orderProductVOS
            this.tableData = this.setShippingFeeTotal(res.data?.orderProductVOS || [])
            this.handleShippingFee()
          }

        })
      },

      // 处理运费分摊，相同sku产品运费合并放在最下面
      handleShippingFee() {
        const list = this.tableData

        if (list?.length) {
          let [skus, de_weight] = [[], []]
          list.forEach((item) => skus.push(item.sku?.split('-')?.[0]))

          de_weight = Array.from(new Set(skus))
          if (de_weight.length != list.length) {
            let newArr = []
            for (let i = 0; i < de_weight.length; i++) {
              const filter_array = list.filter((item) =>
                item.sku.includes(de_weight[i])
              )

              if (filter_array.length > 1) {
                for (let i = 0; i < filter_array.length; i++) {
                  if (i === filter_array.length - 1) {
                    filter_array[i].shippingFeeTotal = this.utils.amountTotal(
                      filter_array,
                      'shippingFee',
                      2
                    )
                  }
                }
              } else if (filter_array.length === 1) {
                filter_array[0].shippingFeeTotal = filter_array[0].shippingFee
              }
              newArr = newArr.concat(filter_array)
            }

            this.tableData = newArr
          } else {
            this.tableData = list.map((item) => {
              return {
                ...item,
                shippingFeeTotal: item.shippingFee,
              }
            })
          }
        }
      },

      // 跳转
      payNow(href) {
        window.open(href, '_blank')
      },

      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
      },

      // 获取pdf地址
      generatePdf() {
        orderpdf({
          orderId: this.orderId,
          printType: 1,
          checkFlag: this.isViewCost ? 1 : 0,
        }).then((res) => {
          if (res && res.code === '000000') {
            const _ = (obj, key) => (obj[key] ? obj[key] : '')

            let file = {
              enclosureName: `${this.detail.piNO}.pdf`,
              fileId: _(res.data, 'fileId'),
              enclosureSize: _(res.data, 'size'),
              enclosureUrl: _(res.data, 'path'),
              suffix: _(res.data, 'suffix'),
              uploadType: 1,
              mailEnclosureId: _(res.data, 'fileId'),
              source: 1,
            }
            let url = `${window.location.origin
              }/#/write?from=business&file=${encodeURI(
                JSON.stringify(file)
              )}&email=${this.custEmail}`
            this.utils.openWindow(url)
          }
        })
      },

      sendMail() {
        this.generatePdf()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .handle-box {
    padding: 10px;
    .btn-send {
      border: none;
      outline: none;
      padding: 6px 10px;
      color: #fff;
      background: #1790ff;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.4s;
    }

    .btn-send:hover {
      opacity: 0.8;
    }

    .check-images {
      margin-left: 10px;
    }
  }

  .waiBox {
    width: 700px;
    margin: 0 auto;
    color: rgba(0,0,0,.89);
  }
  .headBox{
    display: flex;
    line-height: 0;
    .imgBox {
      flex: 1;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .txtBox {
      width: 286px;
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .txtBoxLine{
    font-size: 12px;
    display: flex;
    line-height: 14px;
    margin-bottom: 6px;
    span{
      flex-shrink: 0;
      width: 100px;
      text-align: left;
      // font-weight: bold;
      line-height: 1;
    }
  }
  .shipBillPanle{
    display: flex;
    margin-top: 20px;
    .shipPanleCont{
      margin-bottom: 20px;
      p{
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 10px;
      }
    }
    h3{
      font-weight: normal;
      color: rgba(0,0,0,.89);
      font-size: 14px;
      margin-bottom: 10px;
    }
    .shipPanle{
      flex: 1;
      h3{
        font-weight: 600;
      }
    }
    .billPanle{
      width: 286px;
      margin-left: 40px;
      h3{
        font-weight: 600;
      }
    }
  }
  

  .clearfix:before,
  .clearfix:after {
    content: '';
    display: block;
    clear: both;
  }

  .clearfix {
    zoom: 1;
  }
  .pTL {
    font-size: 18px !important;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;
  }

  /* SHIP TO */
  .shipto {
    margin-bottom: 20px;
  }

  .contBox {
    width: 377px;
    border: 1px solid #000000;
    line-height: 70px;
    float: left;
  }

  .contBox>div {
    border-bottom: 1px solid #000;
    padding: 5px;
  }

  .contBox>div:last-child {
    border-bottom: none;
  }

  .contBox p {
    line-height: 16px;
    font-size: 10px;
  }

  .cust {
    width: 264px;
    height: 118px;
    border: 1px solid #000000;
    background-color: #ffff66;
    margin-left: 54px;
    float: left;
  }

  .cust p {
    font-size: 13px;
    line-height: 18px;
  }

  .cust p i {
    font-size: 10px;
    font-weight: normal;
    background-color: #ffff99;
    border-bottom: 1px solid #000;
  }

  .cust div {
    font-size: 13px;
    line-height: 19px;

    position: relative;
    top: 6px;
  }

  .cust div em {
    width: 227px;
    display: inline-block;
    background-color: hsla(30, 79%, 63%, 0.678);
    font-size: 10px;
    font-weight: normal;
    border-bottom: 1px solid #000;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .cust p span {
    display: inline-block;
  }

  /* 表格 */
  #tableExcel {
    border-collapse: collapse;
  }

  #tableExcel tr td {
    height: 20px;
  }

  #tableExcel tbody tr td {
    font-size: 10px;
    text-align: center;
  }

  #tableExcel tfoot tr td {
    text-align: center;
  }

  .info-panel{
    display: flex;
    margin-top: 30px;
    .ftTxt{
      flex: 1;
      .txtBoxLine{
        margin-bottom: 8px;
        span{
          width: 140px;
        }
      }
    }
    .ftName{
      width: 286px;
      height: 62px;
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .txtBoxLine{
        margin-bottom: 8px;
        span{
          width: 100px;
        }
        i{
          text-decoration: underline;
        }
      }
    }
  }

  .tbxia {
    font-size: 12px;
    text-align: right;
    margin-top: 10px;
    p{
      display: flex;
      justify-content: flex-end;
      .tbxia-title{
        text-align: right;
      }
      .tbxia-cont{
        min-width: 80px;
        text-align: right;
      }
    }
  }

  .tbxia i {
    font-weight: normal;
    margin-left: 10px;
  }

  /* 左右列表 */
  .lrlist {
    margin-top: 10px;
    display: flex;
    color: rgba(0,0,0,.6);
    font-size: 12px;
  }

  .llist {
    flex: 1;
    padding-top: 10px;
    span{
      color: rgba(0,0,0,.89);
      font-weight: bold;
    }
  }

  .lblue {
    font-weight: bold;
    color: blue !important;
    font-size: 14px;
    .c_pointer{
      color: blue !important;
    }
    &.emText{
      font-style: italic;
    }
  }

  .rlist {
    width: 286px;
    margin-left: 40px;
    padding-top: 10px;
    border-top: 1px solid #e8e8e8;
    h3{
      color: rgba(0,0,0,.89);
    }
  }
  .mb8{
    line-height: 1;
    margin-bottom: 8px;
  }

  /* REMARK */
  .ftbb {
    width: 100%;
    min-height: 100px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    margin: 10px 0;
    font-size: 12px;
    box-sizing: border-box;
    padding: 8px;
  }

  .lastDiv p {
    font-size: 12px;
    text-align: left;
  }

  .lastDiv a {
    font-size: 12px;
    color: blue !important;

    text-align: left;
  }

  input {
    padding-left: 5px;
  }

  .text-warp {
    // white-space: pre-wrap;
    white-space: pre-line;
    text-align: left;
    word-break: break-word;
  }
  .tableBox{
    #tableExcel{
      border: 1px solid #e8e8e8;
      border-radius: 3px;
      thead{
        color: rgba(0,0,0,.6);
        font-size: 12px;
        background: #f5f7fa !important;
      }
      tr{
        border: none;
        text-align: left;
        th{
          border: none;
          text-align: left;
          padding:4px;
          font-weight: normal;
        }
        td{
          border: none;
          font-size: 13px;
          text-align: left;
          padding: 4px;
          vertical-align: top;
        }
      }
    }
    .fc-gray{
      color: rgba(0,0,0,.6);
    }
    .text-tr{
      text-align: right !important;
    }
    .pl20{
      padding-left: 20px;
    }
  }
</style>
